import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import logo1 from '../../img/lay00er-1-1.png'; 
import logo2 from '../../img/layer-1-c0opy-1.png'; 
import logo3 from '../../img/layer-1-copy-1.png'; 
import logo4 from '../../img/layer-1-copy0-1-1.png'; 
import logo5 from '../../img//layer-1-copy00-1.png'; 
import logo6 from '../../img/layer-100-1.png'; 
import logo7 from '../../img/layer-789-1.png'; 

export const Logos = ({ className, frameClassName, logos }) => {
  return (
    <div className={`logos ${className}`}>
      <p className="p">Our Clients</p>
      <div className={`frame ${frameClassName}`}>
        {/* {logos.map((logo, index) => (
          <img key={index} className={`layer-${index}`} alt={`Layer ${index + 1}`} src={bg} />
        ))} */}
        <img src={logo1}/>
        <img src={logo2}/>
        <img src={logo3}/>
        <img src={logo4}/>
        <img src={logo5}/>
        <img src={logo6}/>
        <img src={logo7}/>

      </div>
    </div>
  );
};

Logos.propTypes = {
  className: PropTypes.string,
  frameClassName: PropTypes.string,
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
};
